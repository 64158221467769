import React from 'react';

import LogoTollanWorlds from '../../images/logo-tollan-worlds.png';
import LogoTollanSurvivors from '../../images/logo-tollan-survivors.png';

import PicTollansSurvivors from '../../images/pic-tollans-survivors.png';
import AdditionalLayout from '../../images/additional-layout.png';

import ItemGame from './itemGame';

import './Endless.scss';

const Endless = () => {
	const configGame = [
		{
			position: '',
			layout: '',
			logo: LogoTollanWorlds,
			description: 'Action RPG',
			button: 'play-demo',
			pic: {
				type: 'video',
				url: 'https://www.youtube.com/embed/CMcNRodPlkc?si=kBtvA0TpeaOgMwZr',
			},
		},
		{
			position: 'reverse',
			layout: AdditionalLayout,
			logo: LogoTollanSurvivors,
			description: 'ACTION ROGUELITE',
			button: '',
			button: 'coming-soon',
			pic: {
				type: 'img',
				src: PicTollansSurvivors,
			},
		},
	];

	return (
		<div
			className='endless'
			id='products'
		>
			<h2 className='endless-title'>Embark on Endless Adventure</h2>

			<ul className='list-game'>
				{configGame.map((game, index) => (
					<ItemGame
						game={game}
						key={index}
					/>
				))}
			</ul>
		</div>
	);
};

export default Endless;
