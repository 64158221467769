import React from 'react';

import './link-custom.scss';

const LinkCustom = ({ title, path }) => {
	return (
		<a
			className={`link-custom ${title}`}
			href={path}
			target='_blank'
			rel='noopener noreferrer'
		></a>
	);
};

export default LinkCustom;
