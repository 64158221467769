import './Footer.scss';

import LogoTollanUniverseWhite from '../../images/logo-tollan-universe-white.png';

export default function Footer() {
	return (
		<footer className='footer'>
			<div className='container'>
				<div className='footer-coll-2'>
					<h3 className='footer-title'>Links</h3>
					<a
						href='https://twitter.com/TollanWorlds'
						className='footer-link-icon footer-link-icon-1'
					>
						#
					</a>
					<a
						href='https://discord.gg/tollan'
						className='footer-link-icon footer-link-icon-2'
					>
						#
					</a>
					<a
						href='https://medium.com/@tollan'
						className='footer-link-icon footer-link-icon-3'
					>
						#
					</a>
					<a
						href='https://www.youtube.com/@tollanworlds'
						className='footer-link-icon footer-link-icon-4'
					>
						#
					</a>
					<a
						href='https://launcher.elixir.app/games/tollan-worlds'
						className='footer-elixir'
					>
						Elixir
					</a>
				</div>
				<img
					alt='logo'
					src={LogoTollanUniverseWhite}
					className='footer-logo'
				/>
				<div className='footer-coll-3'>
					<div className='footer-fore-img' />
					<div className='footer-coll-3-wrap'>
						<p className='footer-text-1'>© 2024 Foretold Studios Inc.</p>
						<p className='footer-text-2'>
							All rights reserved. All trademarks are the property of their respective owners.
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
}
