import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import LogoTollanUniverse from '../../images/logo-tollan-universe.png';

import './popup-subscribing.scss';

const PopupSubscribing = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [isShow, setShow] = useState(false);

	const isSuccess = searchParams.get('register') === 'success';

	const ononClose = () => {
		setShow(false);
		searchParams.delete('register');
		setSearchParams(searchParams);
	};

	const onShow = () => {
		setShow(true);
	};

	useEffect(() => {
		if (isSuccess) onShow();
	}, [isSuccess]);

	return (
		<>
			<div
				className={`popup-substrate ${isShow ? 'show' : 'hide'}`}
				onClick={ononClose}
			></div>

			<section className={`popup-subscribing ${isShow ? 'show' : 'hide'}`}>
				<div className='play-btn-block-figure-borders' />

				<p className='popup-subscribing-logo'>
					<img
						src={LogoTollanUniverse}
						alt=''
					/>
				</p>
				<p className='popup-subscribing-title'>THANK YOU FOR SUBSCRIBING</p>
				<p className='popup-subscribing-message'>
					Congratulations!
					<br /> <br />
					You’ll now be the first to know about our latest news, exclusive offers and exciting events.
					<br /> <br />
					You may now close this window.
				</p>
			</section>
		</>
	);
};

export default PopupSubscribing;
