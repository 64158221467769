import React from 'react';

import LogoTollanUniverse from '../../images/logo-tollan-universe.png';

import ButtonCustom from '../UI/buttonCustom';

import './Greetings.scss';

export default function Greetings({ onTogglePopup }) {
	return (
		<section
			className='greetings'
			style={{ height: `${window.innerHeight}px` }}
		>
			<div className='greetings-box'>
				<p className='greetings-box-logo'>
					<img
						src={LogoTollanUniverse}
						alt='Tollan Universe'
					/>
				</p>
				<p className='greetings-box-message'>Action adventure game ecosystem</p>
				<ButtonCustom
					title='register-now'
					onClick={onTogglePopup}
				/>
			</div>
		</section>
	);
}
